import Icon, {
  DoubleRightOutlined,
  FileAddOutlined,
  FileImageOutlined,
  InfoCircleOutlined,
  LeftOutlined,
  RightOutlined,
  BoldOutlined,
  ItalicOutlined,
  UnderlineOutlined,
  FontSizeOutlined, // for headings
  BlockOutlined, // for block quote
  OrderedListOutlined,
  UnorderedListOutlined,
  AlignLeftOutlined,
  AlignCenterOutlined,
  AlignRightOutlined,
} from "@ant-design/icons";
import AssignmentImage from "../../../assets/images/assignment-image.svg";
import "./StudentAssignmentAccordion.css";
import React, { useEffect, useState, useCallback } from "react";
import Tooltip from "antd/lib/tooltip";
import { Alert, Button, Image, Spin } from "antd";
import logoBtn from "../../../assets/images/logo-btn.svg";
import {Editable, Slate, useSlate, withReact} from "slate-react";
import { Transforms, createEditor, Node, Editor ,  Element as SlateElement} from "slate";
import {
  useCreateStudentSubmissionMutation,
  useEditStudentSubmissionContentMutation,
} from "../../../services/studentsubmissions.api";
import { useUploadHandwritingMutation } from "../../../services/studentcourseworks.api";
import Swal from "sweetalert2"; // Import SweetAlert2
import pdfToText from "react-pdftotext";
import mammoth from "mammoth";
import CustomSpin from "../../customSpin/CustomSpin";
import { useDispatch } from "react-redux";
import { setNotification } from "../../../redux/app/app.slice";
import { useLestStartRecommendationHelpMutation } from "../../../services/studentsubmitionassisment.api";
import { marked } from "marked";
import {Popover, Tag, ColorPicker} from "antd";

import ButtonSlate from "../../_util/buttonSlate";
import JustifyIcon from "../../icons/justifyIcon";
import ToolbarSlate from "../../_util/toolbarSlate";
import IconSlate from "../../_util/iconSlate";

import isHotkey from 'is-hotkey'
import {useLazyGetRubricByIdQuery} from "../../../services/rubrics.api";
import ViewRubricModal from "../../viewRubricModal/ViewRubricModal";
import HeadingOne from "../../icons/headingOne";
import Bold from "../../icons/boldIcon";
import Italic from "../../icons/italic";
import UnderLine from "../../icons/underLine";
import BoldIcon from "../../icons/boldIcon";
import HeadingTwo from "../../icons/headingTwo";
import ListNum from "../../icons/listNum";
import ListPuce from "../../icons/listPuce";
import TextAlignLeft from "../../icons/textAlignLeft";
import TextAlignCenter from "../../icons/textAlignCenter";
import TextAlignRight from "../../icons/textAlignRight";
import BlockQuote from "../../icons/blockQuote";
import HelpAssistant from "../../HelpAssistant";
import RanderHelpAssistant from "./RanderHelpAssistant";


const HOTKEYS = {
  'mod+b': 'bold',
  'mod+i': 'italic',
  'mod+u': 'underline',
  'mod+`': 'code',
}
const LIST_TYPES = ['numbered-list', 'bulleted-list']
const TEXT_ALIGN_TYPES = ['left', 'center', 'right', 'justify']

const resetEditor = (editor, nodes) => {
  const {selection} = editor;
  console.log("------here");

  editor?.children?.map((item) => {
    Transforms.delete(editor, { at: [0] });
  });
  console.log("------>ww");

  // Insert new nodes if `nodes` parameter is provided
  if (nodes) editor?.insertNodes(Node?.isNode(nodes) ? [nodes] : nodes);
};

console.log("-----------####----------")
const serialize = (value) => {
  return (
    value
      // Return the string content of each paragraph in the value's children.
      .map((n) => Node.string(n))
      // Join them all with line breaks denoting paragraphs.
      .join("\n")
  );
};


export const ColorButton = () => {
  const editor = useSlate();

  const applyColor = (color) => {
    if (color && color.hex) {
      Editor.addMark(editor, 'color', color.hex); // Add color mark
    }
  };

  return (
      <ColorPicker
          onChange={(color) => applyColor(color)}
      >
        <Button>Text Color</Button>
      </ColorPicker>
  );
};


const StudentAssignmentAccordion = ({ assignment, refreshData }) => {

  const renderElement = useCallback(props => <Element {...props} />, [])
  const renderLeaf = useCallback(props => <Leaf {...props} />, [])
  const [rubricId, setRubricId] = useState(null);
  const [getRubricItem, { data: rubricByItem, isLoading: isLoadingRubricItem }] = useLazyGetRubricByIdQuery();
  const [rubricItemData, setRubricItemData] = useState(null);
  const [isViewModalOpen, setIsViewModalOpen] = useState(false);

  useEffect(() => {
    if(assignment){
      setRubricId(assignment?.coursework?.rubric?.id)
    }
  }, [assignment]);

  const rubricShowHandle = async () => {
    const { data, error } = await getRubricItem(rubricId);
    if (data) {
      setRubricItemData(data);
      setIsViewModalOpen(!isViewModalOpen);
    }
    if (error) {
      console.log(error);
      dispatch(
          setNotification({
            message: "Error fetching rubric",
            type: "error",
          })
      );
    }
  }

  const toggleBlock = (editor, format) => {
    const isActive = isBlockActive(
        editor,
        format,
        TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type'
    )
    const isList = LIST_TYPES.includes(format)
    Transforms.unwrapNodes(editor, {
      match: n =>
          !Editor.isEditor(n) &&
          SlateElement.isElement(n) &&
          LIST_TYPES.includes(n.type) &&
          !TEXT_ALIGN_TYPES.includes(format),
      split: true,
    })
    let newProperties
    if (TEXT_ALIGN_TYPES.includes(format)) {
      newProperties = {
        align: isActive ? undefined : format,
      }
    } else {
      newProperties = {
        type: isActive ? 'paragraph' : isList ? 'list-item' : format,
      }
    }
    Transforms.setNodes(editor, newProperties)
    if (!isActive && isList) {
      const block = { type: format, children: [] }
      Transforms.wrapNodes(editor, block)
    }
  }
  const toggleMark = (editor, format) => {
    const isActive = isMarkActive(editor, format)
    if (isActive) {
      Editor.removeMark(editor, format)
    } else {
      Editor.addMark(editor, format, true)
    }
  }
  const isBlockActive = (editor, format, blockType = 'type') => {
    const { selection } = editor
    if (!selection) return false
    const [match] = Array.from(
        Editor.nodes(editor, {
          at: Editor.unhangRange(editor, selection),
          match: n =>
              !Editor.isEditor(n) &&
              SlateElement.isElement(n) &&
              n[blockType] === format,
        })
    )
    return !!match
  }
  const isMarkActive = (editor, format) => {
    const marks = Editor.marks(editor)
    return marks ? marks[format] === true : false
  }
  const Element = ({ attributes, children, element }) => {
    const style = { textAlign: element.align }
    switch (element.type) {
      case 'block-quote':
        return (
            <blockquote style={style} {...attributes}>
              {children}
            </blockquote>
        )
      case 'bulleted-list':
        return (
            <ul style={style} {...attributes}>
              {children}
            </ul>
        )
      case 'heading-one':
        return (
            <h1 style={style} {...attributes}>
              {children}
            </h1>
        )
      case 'heading-two':
        return (
            <h2 style={style} {...attributes}>
              {children}
            </h2>
        )
      case 'list-item':
        return (
            <li style={style} {...attributes}>
              {children}
            </li>
        )
      case 'numbered-list':
        return (
            <ol style={style} {...attributes}>
              {children}
            </ol>
        )
      case 'color':
        return (
            <span style={{ color: element.color }}>{children}</span>
        )
      default:
        return (
            <p style={style} {...attributes}>
              {children}
            </p>
        )
    }
  }
  const Leaf = ({ attributes, children, leaf }) => {
    if (leaf.bold) {
      children = <strong>{children}</strong>
    }
    if (leaf.italic) {
      children = <em>{children}</em>
    }
    if (leaf.underline) {
      children = <u>{children}</u>
    }
    return <span {...attributes}>{children}</span>
  }
  const BlockButton = ({ format, icon }) => {
    const editor = useSlate()
    return (
        <ButtonSlate
            active={
          isBlockActive(
                editor,
                format,
                TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type'
            )}
            onMouseDown={event => {
              event.preventDefault()
              toggleBlock(editor, format)
            }}
        >
          <IconSlate icon={icon} />
        </ButtonSlate>
    )
  }
  const MarkButton = ({ format, icon }) => {
    const editor = useSlate()
    return (
        <ButtonSlate
            active={isMarkActive(editor, format)}
            onMouseDown={event => {
              event.preventDefault()
              toggleMark(editor, format)
            }}
        >
          <IconSlate icon={icon} />
        </ButtonSlate>
    )
  }
  const initialAccordionStates = {
    1: true,
    3: true,
  };
  const [isPending, setIsPending] = useState(false);

  const [accordionStates, setAccordionStates] = useState(
    initialAccordionStates
  );
  const [editor] = useState(() => withReact(createEditor()));
  // Use our deserializing function to read the data from Local Storage.

  const [createStudentSub] = useCreateStudentSubmissionMutation();
  const [uploadHandwritingStudentCourseWork] = useUploadHandwritingMutation();
  const [helpLestStartData] = useLestStartRecommendationHelpMutation();
  const [isStartClicked, setIsStartClicked] = useState(false);
  const [isProgressClicked, setIsProgressClicked] = useState(false);
  const [isReadyToSubmitClicked, setIsReadyToSubmitClicked] = useState(false);

  const [error, setError] = useState(null);
  // const [submissionState,setSubmissionState] = useState(null)

  const [answer, setAnswer] = useState([
    {
      type: "paragraph",
      children: [{ text: "" }],
    },
  ]);
  const [image, setImage] = useState(null);
  const [answerFormated, setAnswerFormated] = useState("");
  const [isSubmited, setIsSubmited] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const [helperAnswer, setHelperAnswer] = useState(null);
  const [isHelpAnswerLoading, setIsHelpAnswerLoading] = useState(false);
  const [isProgressDisabled, setIsProgressDisabled] = useState(true);
  const [activeButton, setActiveButton] = useState(null);
  const [indexSelectedContent, setIndexSelectedContent] = useState(0);
  const [wordsCount, setWordsCount] = useState(0);

  const handleExtractTextFromWordOrPdf = async (event) => {
    const file = event.target.files[0];
    const type = file.type;
    // check if file is word
    if (
      type ===
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
      type === "application/msword"
    ) {
      try {
        setIsLoading(true);
        extractTextFromWord(file).then((text) => {
          const slateContent = [
            { type: "paragraph", children: [{ text: text }] },
          ];
          resetEditor(editor, slateContent);
          setAnswer(slateContent);
          setAnswerFormated(text);
          setIsLoading(false);
          dispatch(
            setNotification({
              type: "success",
              message: "Your WORD upload has been completed successfully.",
            })
          );

          console.log("Extracted text www:", text);
          console.log("Updated Slate content ww:", slateContent);
        });
      } catch (error) {
        setIsLoading(false);
        dispatch(
          setNotification({
            type: "error",
            message: "Failed to extract text from Word!",
          })
        );
        console.error("Failed to extract text from Word:", error);
      }
    } else if (type === "application/pdf") {
      try {
        setIsLoading(true);
        let text = await pdfToText(file);
        // check if text start with "Page 1 of"
        if (text.startsWith("Page 1 of")) {
          text = "";
        }
        const slateContent = [
          { type: "paragraph", children: [{ text: text }] },
        ];
        resetEditor(editor, slateContent);
        setAnswer(slateContent);
        setAnswerFormated(text);
        setIsLoading(false);
        setWordsCount(countWords(slateContent));
        dispatch(
          setNotification({
            type: "success",
            message: "Your PDF upload has been completed successfully.",
          })
        );
      } catch (error) {
        setIsLoading(false);

        dispatch(
          setNotification({
            type: "error",
            message: "Failed to extract text from PDF!",
          })
        );
        console.error("Failed to extract text from PDF:", error);
      }
    }
  };
  const extractTextFromWord = async (file) => {
    try {
      const arrayBuffer = await file.arrayBuffer();
      if (!arrayBuffer) {
        throw new Error("Failed to read file as ArrayBuffer");
      }
      const { value: textContent } = await mammoth.extractRawText({
        arrayBuffer,
      });
      return textContent;
    } catch (error) {
      console.error("Error extracting text from Word file:", error);
      throw error; // Optionally rethrow the error if you want it to be caught by outer try/catch
    }
  };

  const handleExtractTextHandwriting = async (event) => {
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append("file", file); // `file` is the file you want to upload
    formData.append("studentworkId", assignment.id); // Any other data you want to send

    setIsLoading(true);
    const { data, error } = await uploadHandwritingStudentCourseWork(formData);

    if (data) {
      setIsLoading(false);
      dispatch(
        setNotification({
          type: "success",
          message: "Your handwriting upload has been completed successfully.",
        })
      );
      const message = data.message;
      const slateContent = [
        { type: "paragraph", children: [{ text: message }] },
      ];
      resetEditor(editor, slateContent);
      setAnswer(slateContent);
      setAnswerFormated(message);
      setWordsCount(countWords(slateContent));
    }
    if (error) {
      setIsLoading(false);
      dispatch(setNotification({ type: "error", message: "Upload failed!" }));
      console.error("Upload failed:", error);
    }
  };

  const MarkdownRenderer = ({ markdownText }) => {
    const htmlContent = marked(markdownText);

    return (
      <span
        // Use dangerouslySetInnerHTML to render HTML content
        dangerouslySetInnerHTML={{ __html: htmlContent }}
      />
    );
  };
  useEffect(() => {
    if (assignment?.studentSubmission) {
      setIsSubmited(true);
      resetEditor(editor, assignment.studentSubmission.content);
      // setSubmissionState(assignment.studentSubmission.state)
    }
    if (assignment?.coursework?.image) {
      setImage(assignment?.coursework?.image.filePath);
    }
    if (assignment?.studentSubmission?.preAssesmentModePractice?.length) {
      setIndexSelectedContent(
        assignment?.studentSubmission?.preAssesmentModePractice?.length - 1
      );
      const slateContent =  assignment?.studentSubmission?.preAssesmentModePractice[
      assignment?.studentSubmission?.preAssesmentModePractice
          ?.length - 1
          ]?.contentArray;
      resetEditor(editor, slateContent);
    }
    console.log("assignment", assignment);
  }, [assignment]);

  const handleSubmit = async () => {
    const words = answerFormated.trim().split(/\s+/);
    if (words.filter((word) => word).length < 10) {
      dispatch(
        setNotification({
          type: "info",
          message: "Please write at least 10 words to submit your coursework.",
        })
      );
      return;
    }
    setIsPending(true);
    if (!assignment.studentSubmission || assignment.coursework.isPracticeMode) {
      const { data, error } = await createStudentSub({
        content: answer,
        state: "Submitted",
        formated: answerFormated,
        studentCourseWork: "/api/student-course-works/" + assignment.id,
      });

      if (data) {
        setIsSubmited(true);
        dispatch(
          setNotification({
            type: "success",
            message:
              "Thank you for submitting your coursework. Your teacher will review it soon...",
          })
        );
        setIsPending(false);
      }
      if (error) {
        setError(
          "Error, We encountered an issue while trying to create the assesment . Please check your input and try again. If the problem persists, contact support for assistance."
        );
        console.log("error create", error);
        dispatch(
          setNotification({
            type: "error",
            message:
              "Error, We encountered an issue while trying to create the assesment . Please check your input and try again. If the problem persists, contact support for assistance.",
          })
        );
        setIsPending(false);
      }
    }
    /*  else {
      const  { data, error } = await editStudentSub({id:assignment.studentSubmission.id,content:answer,formated:answerFormated})

      if (data){
        setIsSubmited(data)
        if(submissionState!=="Delivered"){
          dispatch(setNotification({type:"success", message:"Thank you for submitting your coursework. Your teacher will review it soon..."}))

        }
      }
      if (error){
        setError("Error, We encountered an issue while trying to update the assesment . Please check your input and try again. If the problem persists, contact support for assistance.")
        console.log("error update",error)
        dispatch(setNotification({type:"error", message:"Error, We encountered an issue while trying to create the assesment . Please check your input and try again. If the problem persists, contact support for assistance."}))
      }
    }*/
  };

  const toggle = (index, state) => {
    setAccordionStates((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  const countWords = (nodes) => {
    const text = nodes.map((node) => Node.string(node)).join(' ');
     return text.trim().split(/\s+/).filter(Boolean)?.length;
  };

  const handleActivate = (id) => setActiveButton(id);

  const handleLetStartHelp = async (assignment) => {
    handleActivate("btn_1");
    setIsHelpAnswerLoading(true);
    if (assignment?.startHelp && assignment?.startHelp?.length > 0) {
      const formatedData = MarkdownRenderer({
        markdownText: assignment?.startHelp,
      });
      setHelperAnswer(formatedData);
      setIsHelpAnswerLoading(false);
    } else {
      try {
        const { data } = await helpLestStartData({
          type: "START",
          courseWorkId: assignment?.coursework.id,
          studentCourseWorkId: assignment?.id,
        });

        if (data) {
          const formatedData = MarkdownRenderer({ markdownText: data });
          setHelperAnswer(formatedData); // Update state with the fetched data
          setIsStartClicked(true);
          refreshData();
        }
      } catch (error) {
        console.error("error", error); // Handle error
      } finally {
        setIsHelpAnswerLoading(false); // Stop loading state after data or error is handled
      }
    }
  };
  const handleVerifyProgress = async (assignment) => {
    handleActivate("btn_2");
    const words = answerFormated.trim().split(/\s+/);
    if (words.filter((word) => word)?.length < 10) {
      setIsHelpAnswerLoading(false);
      dispatch(
        setNotification({
          type: "info",
          message: "Please write at least 10 words to verify your progress.",
        })
      );
      return;
    }
    setIsHelpAnswerLoading(true);

    if (assignment?.progressHelp && assignment?.progressHelp?.length > 0) {
      const formatedData = MarkdownRenderer({
        markdownText: assignment?.progressHelp,
      });
      setHelperAnswer(formatedData);
      setIsHelpAnswerLoading(false);
    } else {
      try {
        const { data } = await helpLestStartData({
          type: "PROGRESS",
          courseWorkId: assignment?.coursework.id,
          answer: answerFormated,
          studentCourseWorkId: assignment?.id,
        });

        if (data) {
          const formatedData = MarkdownRenderer({ markdownText: data });
          setHelperAnswer(formatedData); // Update state with the fetched data
          setIsProgressClicked(true);
          refreshData();
        }
      } catch (error) {
        console.error("error", error); // Handle error
      } finally {
        setIsHelpAnswerLoading(false); // Stop loading state after data or error is handled
      }
    }
  };

  const handleReadyToSubmit = async (assignment) => {
    handleActivate("btn_3");
    const words = answerFormated.trim().split(/\s+/);
    if (words.filter((word) => word)?.length < 10) {
      setIsHelpAnswerLoading(false);
      dispatch(
        setNotification({
          type: "info",
          message: "Please write at least 10 words to submit your coursework.",
        })
      );
      return;
    }
    setIsHelpAnswerLoading(true);
    if (assignment?.submitHelp && assignment?.submitHelp?.length > 0) {
      const formatedData = MarkdownRenderer({
        markdownText: assignment?.submitHelp,
      });
      setHelperAnswer(formatedData);
      setIsHelpAnswerLoading(false);
    } else {
      try {
        const { data } = await helpLestStartData({
          type: "SUBMIT",
          courseWorkId: assignment?.coursework.id,
          answer: answerFormated,
          studentCourseWorkId: assignment?.id,
        });

        if (data) {
          const formatedData = MarkdownRenderer({ markdownText: data });
          setHelperAnswer(formatedData); // Update state with the fetched data
          setIsReadyToSubmitClicked(true);
          refreshData();
          console.log("data ready", data);
        }
      } catch (error) {
        console.error("error", error); // Handle error
      } finally {
        setIsHelpAnswerLoading(false); // Stop loading state after data or error is handled
      }
    }
  };

  useEffect(() => {
    if (assignment?.startHelp && assignment?.startHelp?.length > 0) {
      setIsStartClicked(true);
    }
    if (assignment?.progressHelp && assignment?.progressHelp?.length > 0) {
      setIsProgressClicked(true);
    }
    if (assignment?.submitHelp && assignment?.submitHelp?.length > 0) {
      setIsReadyToSubmitClicked(true);
    }
  }, [assignment]);


  return (
    <>
      <div className={`accordion`}>
        {error && (
          // <div className="__errorMessage __errorField">
          //     <InfoCircleOutlined style={{color: "red"}} /> {error}
          // </div>
          <Alert
            message={error}
            type="error"
            icon={<InfoCircleOutlined />}
            showIcon
            className="__errorField"
          />
        )}
        <div className="accordion__title" onClick={() => toggle(1)}>
          <Tooltip
              title={assignment ? assignment.coursework.description : ""}
              placement={"top"}
          >
            <h1>{assignment ? assignment.coursework.title : ""}</h1>
          </Tooltip>
          <DoubleRightOutlined
              className={`arrow ${accordionStates[1] ? "toggled" : ""}`}
          />
        </div>
        <div
          className={`accordion__content ${
            accordionStates[1] ? "toggled" : ""
          }`}
          style={{ display: "flex" }}
        >
          {image && (
            <div className={"figure-coursework"}>
              <img
                src={`${process.env.REACT_APP_API_BASE_URL}/uploads/images/${image}`}
                alt={assignment ? assignment.coursework.title : ""}
              />
            </div>
          )}
          <div className={"assignment_body"}>
            <div className={"instruction__heading"}>
              <h1>{assignment ? assignment.coursework.title : ""}</h1>

            </div>

            <p>{assignment ? assignment.coursework.description : ""}</p>
            <span><button className={"view-btn"} onClick={rubricShowHandle}>View Rubric</button></span>
          </div>
        </div>
      </div>

      {assignment?.coursework?.writingAssistance && (
          <div className={"accordion"}>
          <div className={"accordion__title__help"} onClick={() => toggle(2)}>
            <h2>Need help? </h2>
            <DoubleRightOutlined
              className={`arrow ${accordionStates[2] ? "toggled" : ""}`}
            />
          </div>
          <div
            className={`accordion__content__help  ${
              accordionStates[2] ? "toggled" : ""
            }`}
          >
            <div className={"group__btn__help"}>
              {isHelpAnswerLoading ? (
                <CustomSpin />
              ) : (
                <>
                  <button
                    onClick={() => {
                      handleLetStartHelp(assignment);
                      //toggle(2);
                      setAccordionStates((prevState) => ({
                        ...prevState,
                        [2]: true,
                      }));
                    }}
                    className={`${isStartClicked ? "clicked" : ""} ${
                      activeButton === "btn_1" ? "activated" : ""
                    }`}
                  >
                    Let’s start…
                  </button>
                  <button
                    onClick={() => {
                      handleVerifyProgress(assignment);
                      setAccordionStates((prevState) => ({
                        ...prevState,
                        [2]: true,
                      }));
                    }}
                    className={`${isProgressClicked ? "clicked" : ""} ${
                      activeButton === "btn_2" ? "activated" : ""
                    }`}
                  >
                    Progress Check
                  </button>
                  <button
                    onClick={() => {
                      handleReadyToSubmit(assignment);
                      setAccordionStates((prevState) => ({
                        ...prevState,
                        [2]: true,
                      }));
                    }}
                    className={`${isReadyToSubmitClicked ? "clicked" : ""} ${
                      activeButton === "btn_3" ? "activated" : ""
                    }`}
                  >
                    Ready to submit?
                  </button>
                </>
              )}
            </div>
            <p>{helperAnswer && helperAnswer}</p>
          </div>
        </div>
      )}

      <div className={`accordion`}>
        <div className="accordion__title-student" onClick={() => toggle(3)}>
          <h1>Student Answer</h1>
          <DoubleRightOutlined
            className={`arrow ${accordionStates[3] ? "toggled" : ""}`}
          />
        </div>
        <div
          className={`accordion__content-student  ${
            accordionStates[3] ? "toggled" : ""
          }`}
        >
          {isLoading ? (
            <CustomSpin />
          ) : (
              <Slate
                  editor={editor}
                  initialValue={answer}
                  onChange={(value) => {
                    const isAstChange = editor.operations.some(
                        (op) => "set_selection" !== op.type
                    );





                    if (isAstChange) {
                      // Serialize the value and save the string value to Local Storage.
                      setAnswer(value);
                      setAnswerFormated(serialize(value));
                      setWordsCount(countWords(value));
                      if (value?.length < 10) {
                        setIsProgressDisabled(true);
                      } else {
                        setIsProgressDisabled(false);
                      }
                    }
                  }}
              >

                <ToolbarSlate>
                  <MarkButton format="bold" icon={<BoldIcon />} />
                  <MarkButton format="italic" icon={<Italic />} />
                  <MarkButton format="underline" icon={<UnderLine />} />
                  <BlockButton format="heading-one" icon={<HeadingOne />} />
                  <BlockButton format="heading-two" icon={<HeadingTwo />} />
                  <BlockButton format="block-quote" icon={<BlockQuote />} />
                  <BlockButton format="numbered-list" icon={<ListNum />} />
                  <BlockButton format="bulleted-list" icon={<ListPuce />} />
                  <BlockButton format="left" icon={<TextAlignLeft />} />
                  <BlockButton format="center" icon={<TextAlignCenter />} />
                  <BlockButton format="right" icon={<TextAlignRight />} />
                  <BlockButton format="justify" icon={<JustifyIcon />} />
                </ToolbarSlate>
                <Editable
                    className={"slate"}
                    style={{minHeight: "400px", padding: "10px"}}
                    placeholder="Type your answer ..."
                    onPaste={(event) => {
                      event.preventDefault();
                      dispatch(
                          setNotification({
                            type: "info",
                            message: "Pasting is not allowed in this field.",
                          })
                      );
                    }}
                    onKeyDown={event => {
                      for (const hotkey in HOTKEYS) {
                        if (isHotkey(hotkey, event)) {
                          event.preventDefault()
                          const mark = HOTKEYS[hotkey]
                          toggleMark(editor, mark)
                        }
                      }
                    }}
                    renderLeaf={renderLeaf}
                    renderElement={renderElement}
                />
              </Slate>
          )}

          <div className="accordion__actions__button">
            <div>
              {assignment?.studentSubmission?.preAssesmentModePractice
                  ?.length > 0 && (
                  <div>
                    <Button
                        type="text"
                        onClick={() => {
                          const slateContent =assignment?.studentSubmission
                                      ?.preAssesmentModePractice[
                                  indexSelectedContent - 1
                                      ]?.contentArray
                          ;

                          resetEditor(editor, slateContent);
                          setIndexSelectedContent((prev) => prev - 1);
                        }}
                        disabled={indexSelectedContent === 0}
                    >
                      <LeftOutlined />
                </Button>
                <span>
                  {`${indexSelectedContent + 1} / ${
                    assignment?.studentSubmission?.preAssesmentModePractice
                      ?.length
                  }`}{" "}
                </span>
                <Button
                  type="text"
                  onClick={() => {
                    const slateContent = assignment?.studentSubmission
                              ?.preAssesmentModePractice[
                              indexSelectedContent + 1
                            ]?.contentArray;

                    resetEditor(editor, slateContent);
                    setIndexSelectedContent((prev) => prev + 1);
                  }}
                  disabled={
                    indexSelectedContent + 1 ===
                    assignment?.studentSubmission?.preAssesmentModePractice
                      ?.length
                  }
                >
                  <RightOutlined />
                </Button>
              </div>
            )}
            </div>
            <div>
              <Popover content={ wordsCount + (wordsCount > 1 ? " Words" : " Word" )} trigger="hover">
                Word Count: {" "}
                <Tag>
                  {wordsCount}
                </Tag>
              </Popover>
            </div>
            <div className={"upload__actions"}>
              {assignment?.coursework?.allowHandWriting && (
                <Button className="accordion__upload-btn">
                  <FileImageOutlined style={{ fontSize: "20px" }} /> image
                  <input
                    type="file"
                    name="myfile"
                    onChange={handleExtractTextHandwriting}
                  />
                </Button>
              )}
              {assignment?.coursework?.allowUploadFile && (
                <Button className="accordion__upload-btn">
                  <FileAddOutlined style={{ fontSize: "20px" }} /> file
                  <input
                    type="file"
                    name="filewordpdf"
                    onChange={handleExtractTextFromWordOrPdf}
                  />
                </Button>
              )}
            </div>
          </div>
        </div>

        {isSubmited && !assignment?.coursework?.isPracticeMode ? (
          <span className="already-submitted">
            {" "}
            <Image
              width={20}
              src={logoBtn}
              alt="Background"
              preview={false}
            />{" "}
            Submitted{" "}
          </span>
        ) : (
          <Button
            type="primary"
            onClick={handleSubmit}
            style={{
              marginLeft: 8,
              float: "right",
              marginTop: 6,
              marginBottom: 10,
            }}
            className={"button button-big btn-correction"}
            disabled={isPending}
          >
            {isPending ? (
              <Spin size="small" />
            ) : (
              <>
                <Image
                  width={20}
                  src={logoBtn}
                  alt="Background"
                  preview={false}
                />{" "}
                Submit
              </>
            )}
          </Button>
        )}
      </div>
      <RanderHelpAssistant studentSubmissionAnswer={answerFormated} assignment={assignment} />
      <ViewRubricModal
          isViewModalOpen={isViewModalOpen}
          setIsViewModalOpen={setIsViewModalOpen}
          //rubricItem={rubricItem}
          rubricItemData={rubricItemData}
      />
    </>
  );
};

export default StudentAssignmentAccordion;

