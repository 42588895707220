import React, {useEffect, useState} from "react";
import HelpAssistant from "../../HelpAssistant";
import {
  IconCopy,
  IconEdit,
  IconExport, IconFileUpload,
  IconNotes,
  IconSettings,
} from "../../../assets/icons";
import PromptGenerator from "../../PromptGenerator";
import {
  useGetConversationSessionByContextAndUserMutation,
  useGetMessagesMutation,
  useHelperAssistantMutation, useLazyGetPresetPromptByContextQuery
} from "../../../services/renderHelperAssistant.api";

const RanderHelpAssistant = ({studentSubmissionAnswer,assignment}) => {
  const [isBusy, setIsBusy] = useState(false);
  const [showPrompt, setShowPrompt] = useState(true);
  const [isChatOpen, setIsChatOpen] = useState(true);
  const [context, setContext] = useState('StudentSubmission')
  const [HelperAssistant] = useHelperAssistantMutation();
  const [messagesList] = useGetMessagesMutation();
  const [conversationSession] = useGetConversationSessionByContextAndUserMutation()
  const [conversation, setConversation] = useState()
  const [presetPromptTrigger, {data: presetPromptData, isLoading}] = useLazyGetPresetPromptByContextQuery()
  const [presetPrompt,setPresetPrompt] = useState([])
  const [messages, setMessages] = useState([]);


  const getConversationSession = async ()=> {
    const {data, error} = await conversationSession({
      code: context
    })
    if (data) {
      setConversation(JSON.parse(data)?.id);
    }
    if(error){

    }
  }
  useEffect(() => {
    getConversationSession();
  }, []);


  useEffect(() => {
    presetPromptTrigger(context)
    if (presetPromptData && presetPromptData['hydra:member']){
      const mappedPrompt =(presetPromptData['hydra:member'])?.map(item=>({
        label :  item.label,
        context :false,
        value: item.value,
      })) ?? []
      setPresetPrompt(mappedPrompt);
    }
  }, [presetPromptData]);


  console.log('presetPromptData @@@', presetPromptData , presetPrompt)

  const getListMessages = async ()=> {
    const {data, error} = await messagesList({
      code: context
    })
    if (data){
      const mappedData = JSON.parse(data)?.map(item=>({
        ...item,
        type: item.role === "Ia" ? "received" : "sent",
      })) ?? []
      setMessages(mappedData);
    }


  }

  useEffect(() => {
    console.log("****", typeof conversation)
  }, [conversation]);
  const handleSubmitPrompt = async (value)=>{

    setIsBusy(true);
    setMessages(prev=> [...prev,({
      type: "sent",
      message:value.value,
    })])
    const formData = new FormData();
    const payload = {
      conversation_id:conversation,
      context: context,
      params :{'message':value.value,"studentSubmissionAnswer":studentSubmissionAnswer},
      studentCourseWorkId: assignment?.id
    }
    // formData.append('files[]', files);
    formData.append('data',JSON.stringify(payload));

    const {data, error } = await HelperAssistant({
      formData: formData,
    });

    if (data){
      setIsBusy(false);

      setMessages(prev=> [...prev,({
        type: "received",
        message:data?.response,
      })])
      console.log("seted conversation ------60>",conversation,!conversation);
      console.log("seted conversation ------60.5>",typeof conversation);

      if (conversation == "null" || !conversation){
        const {data, error} = await conversationSession ({
          code:context
        })
        if (data){
          setConversation(data?.id)
        }

        console.log("seted conversation ------61>",conversation);

      }
      console.log("seted conversation ------62>",conversation);

    }
    console.log("seted conversation ------63>",conversation);

    if (error){
      setIsBusy(false);
      setMessages(prev=> [...prev,({
        type: "received",
        message:"Error from llm, contact support or try to refresh",
      })])
    }

  }




  useEffect(() => {
    getListMessages();
  }, []);




  const tabs = [
    {
      key: "rewrite",
      label: "Rewrite",
      icon: <IconEdit size={16} />,
      context: true,
    },
    {
      key: "notes",
      label: "Notes",
      icon: <IconNotes size={16} />,
      context: true,
    },
    {
      key: "settings",
      label: "Settings",
      icon: <IconSettings size={16} />,
      context: true,
    },
  ];



  const messageOprions = [
    {
      label: "Edit",
      icon: <IconCopy size={16} />,
    },
    {
      label: "Export",
      icon: <IconExport size={16} />,
    },
  ];

  const fields = [
    {
      name: "gradeLevel",
      label: "Grade Level",
      type: "text",
      placeholder: "Enter grade level",
      required: true,
      hint: "This is a hint text to help user.",
    },
    {
      name: "pointScale",
      label: "Point Scale",
      type: "text",
      placeholder: "Enter point scale",
      required: true,
    },
    {
      name: "standard1",
      label: "Standard / Objective 1",
      type: "textarea",
      placeholder: "Enter a description",
      required: true,
      hint: "This is a hint text to help user.",
    },
    {
      name: "objectiveType",
      label: "Objective Type",
      type: "select",
      placeholder: "Select an option",
      required: true,
      options: [
        { label: "Option 1", value: "option1" },
        { label: "Option 2", value: "option2" },
        { label: "Option 3", value: "option3" },
      ],
    },
  ];

  const handleSubmit = (values) => {
    setShowPrompt(false);
    console.log("Form Submitted:", values);
  };

  const _handleSubmitChat  = async (value, files)=>{

    setIsBusy(true);
    setMessages(prev=> [...prev,({
      type: "sent",
      message:value,
      files
    })])

    const formData = new FormData();
    for (let i = 0; i < files.length; i++) {
      formData.append('files[]', files[i]); // 'files[]' pour indiquer plusieurs fichiers
    }

    const payload = {
      conversation_id:conversation,
      context: context,
      params :{'message':value,"studentSubmissionAnswer":studentSubmissionAnswer},
      studentCourseWorkId: assignment?.id
    }
    // formData.append('files[]', files);
    formData.append('data',JSON.stringify(payload));

    const {data, error } = await HelperAssistant({
      formData: formData,
    });

    if (data){
      setMessages(prev=> [...prev,({
        type: "received",
        message:data?.response,
      })])
      if (conversation == "null" || !conversation){
        const {data, error} = await conversationSession ({
          code:context
        })
        if (data){
          setConversation(data?.id)
        }
        setIsBusy(false);

      }

      setIsBusy(false);
    }

    if (error){
      setMessages(prev=> [...prev,({
        type: "received",
        message:"Error from llm, contact support or try to refresh",
      })])
      setIsBusy(false);

    }
    return true
  }

  const renderMessage = (message) => {
    // Check if there are any files to display
    if (message.files?.length) {
      const contentFile = message.files.map((item, index) => {
        // Check if the file is a PDF
        if (item.name.includes('.pdf')) {
          return (
              <div key={index}>
                <div>{message.message}</div> {/* Assuming 'message.message' contains the text you want to display */}
                <IconFileUpload />
              </div>
          );
        } else {
          // For non-PDF files, display an image or handle other file types
          const fileUrl = URL.createObjectURL(item);  // Create a URL for the uploaded file

          return (
              <div key={index}>
                <div>{message.message}</div> {/* Assuming 'message.message' contains the text you want to display */}
                {item.type.startsWith('image/') ? (
                    <img height={100} width={100} src={fileUrl} alt={`file-${index}`} />  // Display image
                ) : (
                    <div>Unsupported file type</div>  // Handle unsupported file types
                )}
              </div>
          );
        }
      });
      return <div>{contentFile}</div>;
    }

    // If there are no files, just return the message text
    return <div>{message.message}</div>;  // Make sure this is a string or valid React element
  };

  return (
      <HelpAssistant
      open={isChatOpen}
      tabs={tabs}
      messages={messages}
      isBusy={isBusy}
      showPrompt={false}
      options={presetPrompt}
      propmtContent={
        <PromptGenerator
          title="Rubric"
          subtitle="Description or instructions to fill the inputs"
          fields={fields}
          onSubmit={handleSubmit}
        />
      }
      onTabClick={(tab) => console.log("tab", tab)}
      onClickOptions={(suggestions) => {
        handleSubmitPrompt(suggestions)
      }}
      onSubmit={_handleSubmitChat}
      renderMessage={renderMessage}
      messageOprions={messageOprions}
    />
  );
};

export default RanderHelpAssistant;
