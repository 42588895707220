import React from "react";

const Export = ({size=16,...props}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.13843 10.2338L12.4523 6.91985L9.13843 3.60596"
        stroke="currentColor"
        strokeWidth="1.32556"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.84741 14.209V9.56957C1.84741 8.86645 2.12673 8.19213 2.62391 7.69495C3.12109 7.19777 3.79541 6.91846 4.49853 6.91846H12.4519"
        stroke="currentColor"
        strokeWidth="1.32556"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Export;
