import {useEffect, useMemo, useRef, useState} from "react";
import style from "./helpAssistant.module.css";
import {
  IconArrowDown,
  IconCopy,
  IconExport,
  IconFileUpload,
  IconPin,
  IconTrash,
} from "../../assets/icons";
import { setNotification } from "../../redux/app/app.slice";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { Dropdown } from "antd";
import { isEmpty } from "slate";
import { FileOutlined, LinkOutlined } from "@ant-design/icons";
const defaultPlaceholder = "🖐️ Hey there, Ask me anything about This topic";
const HelpAssistant = ({
  tabs,
  messages,
  isBusy,
  showPrompt,
  propmtContent,
  options,
  onClickOptions,
  onSubmit,
  placeholder = defaultPlaceholder,
  onTabClick,
  allowPin = true,
  renderMessage = (message) => message.message
}) => {
  const [isChatOpen, setIsChatOpen] = useState(false);
  const [value, setValue] = useState("");
  const dispatch = useDispatch();
  const bodyRef = useRef(null);
  const fileInputRef = useRef(null);
  const [files, setFiles] = useState([]);

  const content = useMemo(()=>(
        messages.map((message, index) => (
            <div
                className={style[`message-${message.type}`]}
                key={"message-" + index}
            >
              <div className={style["message-content"]}>
                {message.type === "received" && (
                    <div className={style["bot-avatar"]}>
                      <img src="/assets/iconOpenWhite.svg" alt="avatar" />
                    </div>
                )}
                <div className={style["message-text"]}>
                  <p>{renderMessage(message)}</p>
                  {message.type === "received" && (
                      <div className={style["message-options"]}>
                        <button onClick={(e) => handelCopy(e, message.message)}>
                          <IconCopy size={15} />
                          Copy
                        </button>
                        <button onClick={(e) => handelExport(e, message.message)}>
                          <IconExport size={15} />
                          Export
                        </button>
                      </div>
                  )}
                </div>
              </div>
            </div>
        ))

  ),[messages])

  useEffect(() => {
    if (bodyRef.current) {
      bodyRef.current.scrollTop = bodyRef.current.scrollHeight;
    }
  }, [messages, isChatOpen, isBusy]);

  const handelClickOption = (e,item) => {
    e.preventDefault();
    onClickOptions?.(item)
    setFiles([]);
  };

  const handelClickTab = (e,tab) => {
    e.preventDefault();
    onTabClick?.(tab);
    setFiles([]);
  };

  const handleFileClick = () => {
    fileInputRef.current?.click();
  };

  const handleFileChange = (event) => {
    const newFiles = Array.from(event.target.files).map((file) => ({
      id: Date.now() + file.name,
      name: file.name,
      size: file.size,
      file: file,
    }));
    setFiles([...files, ...newFiles]);
    event.target.value = "";
  };

  const removeFile = (id) => {
    const updatedFiles = files.map((file) =>
      file.id === id ? { ...file, isRemoving: true } : file
    );
    setFiles(updatedFiles);

    // Wait for animation to complete before removing the file from state
    setTimeout(() => {
      setFiles((prevFiles) => prevFiles.filter((file) => file.id !== id));
    }, 300); // Match the CSS animation duration
  };

  const handelCopy = (e, message) => {
    e.preventDefault();
    navigator.clipboard.writeText(message);
    dispatch(
      setNotification({
        type: "info",
        message: "Copied to clipboard",
      })
    );
  };

  const handelExport = (e, message) => {
    e.preventDefault();
    //TODO: add export function
  };

  const handelSubmit = async (e) => {
    e.preventDefault();
    if (!value) return;
    if (onSubmit) {
       onSubmit(
        value,
        files?.map((file) => file.file)
      );
        setValue("");
        !isChatOpen && setIsChatOpen(true);
        setFiles([]);

      return;
    }
    setValue("");
    setIsChatOpen?.(true);
  };

  const optionsPinMenu = [
    {
      label: (
        <button onClick={handleFileClick} className={style["menu-item"]}>
          <FileOutlined />
          Files (images, pdf, docs)
        </button>
      ),
      key: "0",
    },
    {
      label: (
        <button className={style["menu-item"]}>
          {" "}
          <LinkOutlined />
          Link
        </button>
      ),
      key: "1",
    },
  ];

  return (
    <div className={style["space-help-assistant"]}>
      {isChatOpen && (
        <div
          className={`${style["mask-help-assistant"]} mask-help-assistant`}
        />
      )}
      <div
        className={`${style["container-help-assistant"]} container-help-assistant`}
      >
        <div
          className={`${
            style["content-help-assistant"]
          } content-help-assistant ${isChatOpen ? style["open"] : ""}`}
        >
          <div className={style["header-help-assistant"]}>
            <button
              className={style["close-help-assistant"]}
              onClick={() => setIsChatOpen(false)}
            >
              <img src="/assets/cross.svg" alt="start" />
            </button>
          </div>
          <div className={style["body-help-assistant"]} ref={bodyRef}>
            {showPrompt ? propmtContent :(<>
              {content}
              {isBusy && (
                  <div className={style["typing"]}>
                    <span />
                    <span />
                    <span />
                  </div>
              )}</>) }
          </div>
        </div>
        <div className={style["help-assistant"]}>
          {files?.length > 0 && (
            <div className={`${style["container-files"]} ${isChatOpen ? style["open"] : ""}`}>
              {files.map((file) => (
                <div
                  key={file.id}
                  className={`${style["file-item"]} ${
                    file.isRemoving ? style["removing"] : ""
                  }`}
                >
                  <IconFileUpload size={28} />
                  <div className={style["file-details"]}>
                    <div className={style["file-name"]}>{file.name}</div>
                    <div className={style["file-size"]}>
                      {(file.size / 1024).toFixed(1)} KB
                    </div>
                  </div>
                  <button
                    className={style["delete-btn"]}
                    onClick={() => removeFile(file.id)}
                  >
                    <IconTrash size={20} />
                  </button>
                </div>
              ))}
            </div>
          )}
          {!showPrompt && options?.length > 0 && (
            <div
              className={`${style["container-btns-help"]} ${
                isChatOpen ? style["open"] : ""
              }`}
            >
              {options.map((item) => (
                <button
                  key={item.value}
                  className={style["help-btn"]}
                  onClick={(e) => handelClickOption(e,item)}
                >
                  {item.label}
                </button>
              ))}
            </div>
          )}
          {!showPrompt && isChatOpen && tabs?.length > 0 && (
            <div className={style["container-tabs"]}>
              {tabs?.map((tab) => (
                <button
                  key={tab.key}
                  className={style["tab"]}
                  onClick={(e) => handelClickTab(e, tab)}
                >
                  {tab.icon}
                  <div className={style["tab-label"]}>{tab.label}</div>
                </button>
              ))}
            </div>
          )}
          <div className={style["container-open-help"]}>
            <button
              className={style["open-help-assistant"]}
              onClick={() => {
                setIsChatOpen((prev) => !prev);
              }}
            >
              <img
                src={
                  isChatOpen
                    ? "/assets/iconCloseWhite.svg"
                    : "/assets/iconOpenWhite.svg"
                }
                alt="start"
              />
            </button>

            <form
              className={style["help-assistant-input"]}
              onSubmit={handelSubmit}
            >
              <input
                type="file"
                ref={fileInputRef}
                style={{ display: "none" }}
                onChange={handleFileChange}
                accept="image/*, .pdf, .doc, .docx"
                multiple
              />

              {!showPrompt && allowPin && (
                  <Dropdown
                      menu={{items: optionsPinMenu}}
                      trigger={["click"]}
                      placement="topLeft"
                  >
                    <a href="javascript:void(0)"
                          className={style["pin-btn"]}
                          onClick={(e) => e.preventDefault()}
                      >
                        <IconPin/>
                      </a>
                  </Dropdown>
                )}
              <input
                type="text"
                placeholder={placeholder}
                disabled={showPrompt}
                value={value}
                onChange={(e) => setValue(e.target.value)}
              />
              <button
                className={`${style["send-btn"]} ${
                  !showPrompt && value ? style["active"] : ""
                }`}
                disabled={showPrompt}
              >
                <IconArrowDown
                  style={{ color: !showPrompt && value ? "#fff" : "#64748B" }}
                />
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HelpAssistant;

HelpAssistant.propTypes = {
  placeholder: PropTypes.string,
  showPrompt: PropTypes.bool,
  options: PropTypes.array,
  onClickOptions: PropTypes.func,
  tabs: PropTypes.array,
  onTabClick: PropTypes.func,
  onSubmit: PropTypes.func,
  messages: PropTypes.array,
  isBusy: PropTypes.bool,
  propmtContent: PropTypes.node,
  renderMessage: PropTypes.func,
};
