import React from "react";

const Pin = ({ size = 20, ...props }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 19 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M16.375 11.739L8.68198 19.432C6.92462 21.1893 4.07538 21.1893 2.31802 19.432C0.56066 17.6746 0.56066 14.8254 2.31802 13.068L13.2573 2.12868C14.4289 0.957107 16.3283 0.957107 17.4999 2.12868C18.6715 3.30025 18.6715 5.19975 17.4999 6.37132L6.55158 17.3197M6.56066 17.3107C6.55764 17.3137 6.55462 17.3167 6.55158 17.3197M12.2498 7.37865L4.43934 15.1893C3.85355 15.7751 3.85355 16.7249 4.43934 17.3107C5.02211 17.8934 5.9651 17.8964 6.55158 17.3197"
        stroke="#94A3B8"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Pin;
