import React from "react";
import PropTypes from 'prop-types'

const Write = ({ size = 25, ...props }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.1218 4.36583H6.92183C5.24167 4.36583 4.40159 4.36583 3.75985 4.69281C3.19537 4.98043 2.73643 5.43937 2.44881 6.00386C2.12183 6.64559 2.12183 7.48567 2.12183 9.16583V17.5658C2.12183 19.246 2.12183 20.0861 2.44881 20.7278C2.73643 21.2923 3.19537 21.7512 3.75985 22.0388C4.40159 22.3658 5.24167 22.3658 6.92183 22.3658H15.3218C17.002 22.3658 17.8421 22.3658 18.4838 22.0388C19.0483 21.7512 19.5072 21.2923 19.7948 20.7278C20.1218 20.0861 20.1218 19.246 20.1218 17.5658V13.3658M8.1218 16.3658H9.79634C10.2855 16.3658 10.5301 16.3658 10.7603 16.3106C10.9644 16.2616 11.1595 16.1808 11.3384 16.0711C11.5402 15.9474 11.7132 15.7745 12.0591 15.4286L21.6218 5.86583C22.4503 5.0374 22.4503 3.69425 21.6218 2.86583C20.7934 2.0374 19.4503 2.0374 18.6218 2.86583L9.05906 12.4286C8.71316 12.7745 8.5402 12.9474 8.41652 13.1493C8.30686 13.3282 8.22605 13.5233 8.17706 13.7274C8.1218 13.9575 8.1218 14.2021 8.1218 14.6913V16.3658Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Write;

Write.propTypes = {
  size: PropTypes.number,
};