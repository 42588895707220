import React from "react";

const Notes = ({ size = 16, ...props }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1_43066)">
        <path
          d="M9.55344 1.69775H4.52273C4.16213 1.69775 3.8163 1.841 3.56132 2.09599C3.30633 2.35097 3.16309 2.6968 3.16309 3.0574V13.9346C3.16309 14.2952 3.30633 14.641 3.56132 14.896C3.8163 15.151 4.16213 15.2942 4.52273 15.2942H12.6806C13.0412 15.2942 13.3871 15.151 13.642 14.896C13.897 14.641 14.0403 14.2952 14.0403 13.9346V8.90389"
          stroke="currentColor"
          strokeWidth="1.35965"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M1.7998 4.42041H4.5191"
          stroke="currentColor"
          strokeWidth="1.35965"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M1.7998 7.13916H4.5191"
          stroke="currentColor"
          strokeWidth="1.35965"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M1.7998 9.85791H4.5191"
          stroke="currentColor"
          strokeWidth="1.35965"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M1.7998 12.5767H4.5191"
          stroke="currentColor"
          strokeWidth="1.35965"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.9753 4.1629C15.2461 3.89209 15.3983 3.52479 15.3983 3.1418C15.3983 2.75882 15.2461 2.39152 14.9753 2.12071C14.7045 1.84989 14.3372 1.69775 13.9542 1.69775C13.5712 1.69775 13.2039 1.84989 12.9331 2.12071L9.52719 5.52799C9.36556 5.68953 9.24725 5.8892 9.1832 6.10856L8.61418 8.05965C8.59712 8.11815 8.5961 8.18016 8.61122 8.23919C8.62634 8.29822 8.65706 8.3521 8.70014 8.39518C8.74323 8.43827 8.79711 8.46898 8.85614 8.48411C8.91517 8.49923 8.97718 8.49821 9.03567 8.48114L10.9868 7.91213C11.2061 7.84808 11.4058 7.72977 11.5673 7.56814L14.9753 4.1629Z"
          stroke="currentColor"
          strokeWidth="1.35965"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1_43066">
          <rect
            width="16.3158"
            height="16.3158"
            fill="white"
            transform="translate(0.442383 0.342285)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Notes;
