import React from "react";

const Copy = ({size=16,...props}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 18 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1_40498)">
        <path
          d="M13.5273 5.90283H6.89953C6.16745 5.90283 5.57397 6.4963 5.57397 7.22839V13.8562C5.57397 14.5883 6.16745 15.1817 6.89953 15.1817H13.5273C14.2594 15.1817 14.8529 14.5883 14.8529 13.8562V7.22839C14.8529 6.4963 14.2594 5.90283 13.5273 5.90283Z"
          stroke="currentColor"
          strokeWidth="1.32556"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M2.92297 11.2052C2.19391 11.2052 1.59741 10.6087 1.59741 9.87962V3.25183C1.59741 2.52277 2.19391 1.92627 2.92297 1.92627H9.55076C10.2798 1.92627 10.8763 2.52277 10.8763 3.25183"
          stroke="currentColor"
          strokeWidth="1.32556"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1_40498">
          <rect
            width="16.9009"
            height="15.9067"
            fill="white"
            transform="translate(0.4646 0.191895)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Copy;
