import React from "react";

const Fileupload = ({size=36,...props}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect x="2" y="2" width="32" height="32" rx="16" fill="#A6C4FB" />
      <rect
        x="2"
        y="2"
        width="32"
        height="32"
        rx="16"
        stroke="#DCE8FF"
        stroke-width="4"
      />
      <path
        d="M18.7335 17.9482L17.3442 19.7196L16.6103 18.7839C16.5969 18.7669 16.5799 18.753 16.5604 18.7435C16.5409 18.7341 16.5195 18.7291 16.4978 18.7291C16.4761 18.7291 16.4547 18.7341 16.4352 18.7435C16.4157 18.753 16.3987 18.7669 16.3853 18.7839L14.6032 21.0554C14.5867 21.0764 14.5764 21.1017 14.5736 21.1283C14.5708 21.1549 14.5756 21.1817 14.5873 21.2057C14.599 21.2298 14.6173 21.25 14.64 21.2642C14.6627 21.2783 14.6889 21.2858 14.7157 21.2857H21.2853C21.4049 21.2857 21.471 21.1482 21.3978 21.0554L18.9603 17.9482C18.9468 17.9311 18.9295 17.9173 18.9099 17.9078C18.8902 17.8983 18.8687 17.8934 18.8469 17.8934C18.8251 17.8934 18.8036 17.8983 18.7839 17.9078C18.7643 17.9173 18.7471 17.9311 18.7335 17.9482ZM15.2853 16.75C15.2853 16.9394 15.3606 17.1211 15.4945 17.2551C15.6285 17.389 15.8101 17.4643 15.9996 17.4643C16.189 17.4643 16.3707 17.389 16.5047 17.2551C16.6386 17.1211 16.7139 16.9394 16.7139 16.75C16.7139 16.5606 16.6386 16.3789 16.5047 16.2449C16.3707 16.111 16.189 16.0357 15.9996 16.0357C15.8101 16.0357 15.6285 16.111 15.4945 16.2449C15.3606 16.3789 15.2853 16.5606 15.2853 16.75ZM24.1174 14.0107L20.2746 10.1679C20.1674 10.0607 20.0228 10 19.871 10H12.2853C11.9692 10 11.7139 10.2554 11.7139 10.5714V25.4286C11.7139 25.7446 11.9692 26 12.2853 26H23.7139C24.0299 26 24.2853 25.7446 24.2853 25.4286V14.4161C24.2853 14.2643 24.2246 14.1179 24.1174 14.0107ZM22.9674 14.6786H19.6067V11.3179L22.9674 14.6786ZM22.9996 24.7143H12.9996V11.2857H18.3924V15.1429C18.3924 15.3418 18.4715 15.5325 18.6121 15.6732C18.7528 15.8138 18.9435 15.8929 19.1424 15.8929H22.9996V24.7143Z"
        fill="#000095"
      />
    </svg>
  );
};

export default Fileupload;
