import { api } from './api';

export const renderHelperAssistantApi = api.injectEndpoints({
    endpoints: (builder) => ({

        helperAssistant: builder.mutation({
            query: ({ formData }) => ({
                url: `/langchain-chatbot`,
                // headers: {'Content-Type': 'multipart/form-data'},
                method: 'POST',
                body: formData,
                attachments: formData,
            }),
            invalidatesTags: [{ id: 'ALL'}],

        }),
        getMessages: builder.mutation({
        query: (params) => ({
            url: 'api/chat-messages',
            method: 'POST',
            body : JSON.stringify(params),
            headers: {'Content-Type': 'application/json'},


        }),
            invalidatesTags: [{ id: 'ALL'}],
        }),

        getConversationSessionByContextAndUser: builder.mutation({
            query: (data) => ({
                url: `api/conversation-session`,
                method: 'POST',
                body : JSON.stringify(data),
                headers: {'Content-Type': 'application/json'},
            }),
            providesTags: (result, error, { code }) => [{ type: 'Assistant', id: code }],
        }),
        getPresetPromptByContext: builder.query({
            query: (context) => ({
                url: `api/preset-prompts?context${context}`,
                method: 'GET',
                headers: {'Content-Type': 'application/json'},
            }),
            invalidatesTags: (result, error, { code }) => [{ type: 'Assistant', id: code }],
        }),



    }),
});

export const { useHelperAssistantMutation, useGetMessagesMutation , useGetConversationSessionByContextAndUserMutation, useLazyGetPresetPromptByContextQuery} = renderHelperAssistantApi;