import React from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import styles from "./promptGenerator.module.css"; // Import CSS module
import { Select } from "antd";
import { IconWrite } from "../../assets/icons";
import PropTypes from 'prop-types'

const PromptGenerator = ({ title, subtitle, fields, onSubmit}) => {

    const validationSchema = Yup.object(
    fields.reduce((schema, field) => {
      if (field.required) {
        schema[field.name] = Yup.string().required(
          `${field.label} is required`
        );
      } else {
        schema[field.name] = Yup.string();
      }
      return schema;
    }, {})
  );
  const initialValues = fields.reduce((values, field) => {
    values[field.name] = field.defaultValue || "";
    return values;
  }, {});

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <span className={styles.icon}>
          <IconWrite size={24} />
        </span>

      </div>
      <div>
        <h1 className={styles.title}>{title}</h1>
        <p className={styles.subtitle}>{subtitle}</p>
      </div>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          onSubmit(values);
        }}
        validateOnBlur={false}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit} className={styles.form}>
            {fields.map((field, index) => (
              <div key={"fields" + index} className={styles.formGroup}>
                <label htmlFor={field.name} className={styles.label}>
                  {field.label}
                </label>
                {field.type === "textarea" && (
                  <textarea
                    id={field.name}
                    name={field.name}
                    placeholder={field.placeholder || ""}
                    value={values[field.name]}
                    onChange={handleChange}
                    className={`${styles.input} ${
                      errors[field.name] ? styles.error : ""
                    }`}
                  />
                )}
                {field.type === "select" && (
                  <Select
                    defaultValue="active"
                    id={field.name}
                    name={field.name}
                    value={values.state}
                    onChange={(value) => setFieldValue(field.name, value)}
                    className={`${styles.inputSelect} ${
                      errors[field.name] && touched[field.name]
                        ? styles.error
                        : ""
                    }`}
                    options={field?.options || []}
                  />
                )}

                {field.type === "text" && (
                  <input
                    id={field.name}
                    name={field.name}
                    type="text"
                    placeholder={field.placeholder || ""}
                    value={values[field.name]}
                    onChange={handleChange}
                    className={`${styles.input} ${
                      errors[field.name] && touched[field.name]
                        ? styles.error
                        : ""
                    }`}
                  />
                )}
                {field.hint && (
                  <small className={styles.hint}>{field.hint}</small>
                )}
                {errors[field.name] && touched[field.name] && (
                  <div className={styles.errorMessage}>
                    {errors[field.name]}
                  </div>
                )}
              </div>
            ))}
            <button type="submit" className={styles.submitButton}>
              Generate
            </button>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default PromptGenerator;

PromptGenerator.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  fields: PropTypes.array,
  onSubmit: PropTypes.func,
};
