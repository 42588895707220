import React from "react";

const Settings = ({ size = 16, ...props }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 18 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14.3809 5.1001H8.26245"
        stroke="currentColor"
        strokeWidth="1.35965"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.3008 11.897H4.18237"
        stroke="currentColor"
        strokeWidth="1.35965"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.341 13.9369C13.4674 13.9369 14.3805 13.0238 14.3805 11.8974C14.3805 10.771 13.4674 9.85791 12.341 9.85791C11.2146 9.85791 10.3015 10.771 10.3015 11.8974C10.3015 13.0238 11.2146 13.9369 12.341 13.9369Z"
        stroke="currentColor"
        strokeWidth="1.35965"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.54607 7.13998C6.67244 7.13998 7.58554 6.22688 7.58554 5.10051C7.58554 3.97414 6.67244 3.06104 5.54607 3.06104C4.4197 3.06104 3.50659 3.97414 3.50659 5.10051C3.50659 6.22688 4.4197 7.13998 5.54607 7.13998Z"
        stroke="currentColor"
        strokeWidth="1.35965"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Settings;
