import React from "react";

const Edit = ({ size = 16, ...props }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.67822 13.9399H14.7966"
        stroke="currentColor"
        strokeWidth="1.35965"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.6517 2.80402C11.9224 2.53339 12.2894 2.38135 12.6722 2.38135C13.0549 2.38135 13.4219 2.53339 13.6926 2.80402C13.9632 3.07465 14.1152 3.4417 14.1152 3.82443C14.1152 4.20717 13.9632 4.57422 13.6926 4.84485L5.52788 13.0102C5.36614 13.172 5.16623 13.2903 4.94663 13.3542L2.99417 13.9239C2.93567 13.941 2.87366 13.942 2.81463 13.9269C2.75561 13.9117 2.70173 13.881 2.65864 13.8379C2.61555 13.7949 2.58484 13.741 2.56972 13.682C2.55459 13.6229 2.55562 13.5609 2.57268 13.5024L3.14237 11.55C3.20643 11.3306 3.32473 11.1309 3.48636 10.9694L11.6517 2.80402Z"
        stroke="currentColor"
        strokeWidth="1.35965"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.7173 3.73682L12.7568 5.77629"
        stroke="currentColor"
        strokeWidth="1.35965"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Edit;
